@tailwind base;
@tailwind components;
@tailwind utilities;
@import './rc-slider';
@import './font';

@layer base {
  html {
    font-family: Montserrat, sans-serif;
  }

  li::marker {
    font-size: 20px;
    margin-left: 15px;
  }
}

@import './custom';
body {
  background-color: #e0e0e0;

  .main {
    margin: 100px 50px;
    font-family: sans-serif;

    --slider-background: #00b7d7;
    --tooltip-background: #001010;
    --tooltip-text-color: #fffefe;
  }

  .bar {
    --bar-height: 4px;
    height: 4px;
    background-color: #efefef;
    position: relative;
  }

  .progress {
    position: absolute;
    left: 0;
    width: 200px;
    height: 4px;
    background-color: #35b050;
    transform-origin: center left;
  }

  .tooltip {
    background-color: var(--tooltip-background);
    color: var(--tooltip-text-color);
    position: absolute;
    font-size: 12px;
    bottom: 10px;
    user-select: none;
    white-space: nowrap;
  }

  .handle-root {
    position: absolute;
    top: calc((16px - 4px) / -2);
    width: 16px;
    height: 16px;
  }

  .handle-arrow {
    position: absolute;
    top: calc(16px / -2);
    left: 2px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-top-color: var(--tooltip-background);
  }

  .handle {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 16px;
    width: 16px;
    border-radius: 16px;
    border: 2px solid #fff;
    background-color: #35b050;
    box-shadow: 0px 2px 12px rgba(61, 13, 149, 0.18);
    cursor: pointer;
  }

  .handle:hover {
    filter: brightness(0.9);
  }

  .placeholder-light-gray::placeholder {
    color: #757575;
    font-weight: 500;
  }

  .custom-select-input {
    background: url('/image/caret-down.png') no-repeat right !important;
    appearance: none !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    -ms-appearance: none !important;
    -o-appearance: none !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    background-size: 25px 25px !important;
    background-position: 99% 50% !important;
    // padding-left: 40px;
  }

  .custom-select-input-right {
    background: url('/image/caret-down.png') no-repeat left !important;
    appearance: none !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    -ms-appearance: none !important;
    -o-appearance: none !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    background-size: 25px 25px !important;
    background-position: 1% 50% !important;
    // padding-left: 40px;
  }
  
  .custom-select-input-cart-dropdown {
    background: url('/image/caret-down.png') no-repeat right !important;
    appearance: none !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    -ms-appearance: none !important;
    -o-appearance: none !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    background-size: 25px 25px !important;
    background-position: 110% 5% !important;
  }
  .custom-select-input-cart-dropdown-rtl {
    background: url('/image/caret-down.png') no-repeat left !important;
    appearance: none !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    -ms-appearance: none !important;
    -o-appearance: none !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    background-size: 25px 25px !important;
    background-position: -15% 5% !important;
  }

  .font-basic-bold {
    font-weight: 500;
  }

  .font-medium-bold {
    font-weight: 600;
  }

  .apollo-box-shadow {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  }
}

input:checked + div {
  @apply border-apollo-primary;
}

input:checked + div svg {
  @apply block;
}

.cat-btn {
  position: relative;
}

.cat-links {
  position: absolute;
  top: 70%;
  display: none;

  li {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #595959;
  }
}

.cat-btn:hover .cat-links {
  display: block;
}

.menu-link {
  padding: 6px;

  button {
    padding: 6px;
  }
}

.menu-link:hover a {
  background: #fff;
  border-radius: 8px;
  color: #2e8f66 !important;
}

.menu-link:hover .nav-link {
  background: #fff;
  border-radius: 8px;
  color: #2e8f66 !important;
}

.menu-link:hover {
  button {
    background: #fff;
    border-radius: 8px;
    color: #2e8f66 !important;
  }
}

.swiper {
  width: 100%;
  border-radius: 18px;

  @media screen and (max-width: 768px) {
    border-radius: 0;
  }

  .swiper-button-next,
  .swiper-button-prev {
    color: #757575 !important;
    font-weight: bolder;
  }
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  // object-fit: contain;
}

.swiper-pagination-bullet {
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #000;
  opacity: 1;
  background: rgba(0, 0, 0, 0.2);
}

.swiper-pagination-bullet-active {
  color: #fff;
  background: #007aff;
}

input[type='date'] {
  text-align: right;
}

input[type='date']:not(.has-value):before {
  margin-right: 0.5em;
}

.swiper-slide{
  background-color: transparent !important;
}

.mySwiper2 {
  position: unset !important;
  img {
    border-radius: 8px;
  }

  @media screen and (min-width: 1023px) {
    .swiper-pagination {
      display: none;
    }
  }

  .swiper-button-prev {
    left: 1%;
    top: 41%;
    background: url('/image/icon-left.png') no-repeat right !important;

    &::after {
      content: '' !important;
    }

    @media screen and (min-width: 1165px) {
      // left: -5%;
    }
  }

  .swiper-button-next {
    right: 1%;
    top: 41%;
    background: url('/image/icon-right.png') no-repeat right !important;

    &::after {
      content: '' !important;
    }

    @media screen and (min-width: 1165px) {
      // right: -5%;
    }
  }

  &.swiper-rtl {
    .swiper-button-prev {
      transform: rotate(180deg);

      @media screen and (min-width: 1165px) {
        // right: -5%;
      }
    }

    .swiper-button-next {
      transform: rotate(180deg);

      @media screen and (min-width: 1000px) {
        // left: -5%;
      }
    }
  }
}

.modalWidth {
  width: 70%;
  @media screen and (max-width: 1155px) {
    width: 99%;
  }
}

.mySwiper {
  width: 100%;
  height: 60px;
  background-color: transparent !important;

  @media screen and (max-width: 1023px) {
    height: 30px;
  }

  .swiper-wrapper {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .swiper-slide {
    width: 96px !important;
    height: 41px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.sliderThumb {
  width: 100%;
  height: 100%;
}

.ap-headline {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #595959;
}

.text-stepi {
  color: #4c4c4c !important;
}

.address-copy {
  svg {
    fill: red;
  }
}

.orderModal {
  width: 566px;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 16px;
}

.orderModal:focus-visible {
  outline: none !important;
}

.middleSwiper {
  width: 100%;
  height: 100%;
  border-radius: 0 !important;

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper-pagination-bullets {
    bottom: 143px !important;

    span {
      background: #001010;
    }
  }

  .middlesliderimg {
    width: 100%;
    height: 286px;
    position: relative;

    img {
      width: 100% !important;
      height: 286px !important;
      object-fit: cover;
    }
  }
}

.autocomplete-li:hover {
  background-color: #ececec !important;
}

.custom-box-shadow {
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
}
input#datepicker {
  background: none !important;
  margin: 5px;
}


.productCarousel {
  display: block;
  background: #fff;
  width: auto;
  height: auto;

  .swiper-slide {
    width: 176px;
    height: auto;
  }
}

.mobile-nav-item svg {
  filter: invert(53%) sepia(53%) saturate(489%) hue-rotate(81deg) brightness(94%) contrast(105%);
}

.bell-icon svg:nth-child(1) {
  filter: invert(53%) sepia(53%) saturate(489%) hue-rotate(81deg) brightness(80%) contrast(105%);
}

.notif-icon svg {
  filter: grayscale(100%) !important;
}

.notif-card {
  &:hover span {
    color: #2e8f66;
  }
}

.link-item {
  padding: 5px;

  &:hover {
    background: #35b050 !important;

    span {
      color: #fff !important;
    }

    svg {
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(132deg) brightness(153%) contrast(103%);
    }
  }
}

.applePay {
  display: inline-block;
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: plain; /* Use any supported button type. */
  cursor: pointer;
  height: 53px;
  border-radius: 8px;
  transition: all ease 0.25s;
  opacity: 0.9;

  &.pdp {
    margin: 0 0 0 10px;
    -apple-pay-button-type: buy;
    height: 50px;
    border-radius: 5.8px;

    html[dir='rtl'] & {
      margin: 0 10px 0 0;
    }
  }
}

.addToCart {
  background-color: #f8f8f8;
  border: none;
}

.applePayBlack {
  -apple-pay-button-style: black;
}

.applePayWhite {
  -apple-pay-button-style: white-outline;

  @media screen and (min-width: 768px) {
  -apple-pay-button-style: white;
  }
}

@media screen and (min-width: 768px) {
  .applePay {
    &.pdp {
      height: 56px;
    }
  }
}

.sub-link-item {
  background-color: transparent !important;

  &:hover {
    span {
      color: #35b050 !important;
    }

    svg {
      filter: invert(55%) sepia(47%) saturate(657%) hue-rotate(81deg) brightness(95%) contrast(86%);
    }
  }
}

.swiper-pagination-bullet {
  background: #35b050 !important;
  transition: 1s linear ease-in-out;
}

.swiper-pagination-bullet-active {
  width: 25px !important;
  border-radius: 20px !important;
  transition: 1s linear ease-in-out;
}

.tracking-shadow {
  box-shadow: 0px 11px 0px #888888ad, 0px -10px 23px #88888885;
}

.mapGray {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.smartrlt .smartbanner { direction: rtl !important; } 
.smartrlt .smartbanner-wrapper{ direction: ltr !important;display: inline-flex!important ;} 
.smartrlt .smartbanner-info { padding-right: calc(.5em) !important; padding-left: 0!important; }  
.smartrlt .smartbanner-button { left: .8em !important; right: auto !important; } 
.smartbanner-top {
  position: inherit !important;
  top: 0;
}
.smartbanner-show.smartbanner-margin-top { margin-top: 0px!important;}

.mobilenav {
  border: 2.9px solid #35b050;
  border-radius: 9px;
  padding: 3px 3px 0 3px;
  position: relative;
  width: 50px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;

}


.logoinmobile{
  margin-right: 40px;
}
@media only screen and (max-width: 1024px) {
  .noshow{display: none;}
} 
@media only screen and (max-width: 414px) {
  .hideonmobile {
    display: none;
  }
  .lng{
    display: none;
  }

.logoinmobile{
  margin-right: -19px;
}
}

.itemcount {
  top: -10px;
  right: 20%;
}

@media only screen and (min-width: 767px) and (max-width: 1024px) {
  .itemcount {
    right: 30% !important;
  }
   
}

.cart-icon {
  color: #757575;

}

.fulloverlay {
  left: 0;
  top: 0%;
  background-color: rgba(0, 0, 0, 0.1);
  height: 100vh;
  position: fixed;
  z-index: 101 !important;
}
@media screen and (min-width: 1023px) {
  .hide-1024 {
    display: none !important;
  }
}
.pdpSwiper {
  display: flex;
  width: 100%;
  margin-top: 20px;

  .swiper-slide{
    width: 75px !important;
    height: 53px !important;
    
    img{
      object-fit: contain !important;
    }
  }
}

.react-datepicker__close-icon{
  display: none !important;
}
